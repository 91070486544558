.fr-wrapper .fr-placeholder {
  margin-left: 8px !important;
  margin-top: 28px !important;
  width: 100% !important;
  padding-top: 0 !important;
}

.input-field.rich,
.input-field.rich-editing {
  transition: all 0.2s ease;
}

.rich-editing .fr-box.fr-basic .fr-element.fr-view {
  min-height: 50vh;
}

.no-expand .fr-box.fr-basic .fr-element.fr-view {
  min-height: 300px;
}

.fr-box.fr-basic.fr-top .fr-wrapper,
.fr-box {
  box-shadow: none;
  border-bottom: none;
}

.fr-box {
  transition: border 0.2s ease-out;
  border-bottom: 2px solid #888;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.editing .fr-box {
  border-color: #3d75b2;
}

.fr-toolbar {
  height: 0;
  overflow: hidden;
}

.fr-popup.fr-desktop.fr-active {
  z-index: 1000 !important;
}

.rich-editing .fr-toolbar {
  height: auto;
  overflow: visible;
}

.no-expand .fr-toolbar {
  height: auto;
}

.rich-text-modal .fr-box.fr-basic .fr-element.fr-view {
  min-height: 50vh;
}

.fr-element.fr-view {
  overflow: auto;
  margin-top: 0;
  background: #fff;
}

.fr-element.fr-view p {
  margin: 0.55em 0;
}

.fr-box.fr-basic .fr-wrapper {
  background: transparent;
}

.rich-text-field {
  border-top: 3px solid #3d75b2;
  border-bottom: 1px solid #3d75b2;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  min-height: 53px;
}
.rich-text-field.rich-text-disabled {
  border-color: transparent;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.04);
}

.rich-modal {
  position: relative;
}
.rich-modal-edit {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  cursor: pointer;
}
.rich-modal-edit-text {
  font-size: 2em;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rich-modal:hover .rich-modal-edit {
  display: block;
}
