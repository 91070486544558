.description {
  color: var(--antd-black);
}

.descriptions {
  flex: 1 1 0;
}

.subjectDescriptionTitle {
  display: flex;
  gap: 8px;
  align-items: center;
}
