.table {
  overflow-y: auto;
  --nested-row-border: 3px solid #bbbbbb;
  --row-transition-duration: 50ms;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tableFixFirstCol {
  position: relative;
  /* TODO: in spite of this SO answer:
  https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
  this is not working as expected: the borders still vanish. */
  border-collapse: separate;
  border-spacing: 0;
}

.table > .toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table > .toolbar > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.table table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}
.table table thead {
  border-bottom: 2px solid #d9d9d9;
}
.table table thead tr {
  background-color: #f5f5f5;
}
.table table thead tr th {
  text-align: left;
}

.table tr {
  transition: background-color var(--row-transition-duration);
}
.table tr:last-child td {
  border-bottom: 0;
}

.table tr:hover {
  background-color: #f0f0f0;
}

.table tbody[data-stripe='true'] tr:nth-of-type(2n) {
  background-color: #f6f6f6;
}
.table tr:nth-of-type(2n):hover {
  background-color: #eeeeee;
}
.tableFixFirstCol tbody[data-stripe='true'] tr:nth-of-type(2n) td:first-child {
  background-color: #f6f6f6;
}
.tableFixFirstCol tr:nth-of-type(2n):hover {
  background-color: #eeeeee;
}

.table tr .actionIcons {
  opacity: 0;
  transition: opacity var(--row-transition-duration);
  cursor: pointer;
  font-size: 16px;
  color: #555555;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.table tr:hover .actionIcons {
  opacity: 1;
}

.table th,
.table td {
  max-width: 250px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
}

.table th {
  padding: 10px;
}
.table td {
  padding: 4px 10px;
}

.table th:last-child,
.table td:last-child {
  border-right: 0;
}

.tableRow.nested.first {
  border-top: var(--nested-row-border);
}
.tableRow.nested.last {
  border-bottom: var(--nested-row-border);
}

.tableFixFirstCol td:first-child, .tableFixFirstCol th:first-child {
  background-color: white;
  position: sticky;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  left: 0;
}
