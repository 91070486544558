.criteriaNavigator {
  display: flex;
  gap: 30px;
}

.criteriaNavigator > * {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: auto;
}

.selectedCriterion {
  display: flex;
  flex: 2;
  align-items: stretch;
  flex-direction: column;
}
.selectedCriterionDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background-color: var(--background-blue);
}
.criteriaControls {
  margin-top: 12px;
  align-self: flex-start;
}
