.rating {
  flex: 1 1 0;
  display: flex;
  gap: 8px;
  touch-action: none;
}

.ratingCanvasColumn {
  flex: 3 1 0;
  display: flex;
}

.unratedPoolColumn {
  flex: 2 1 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}
.ratingDetailPanelWrapper {
  flex: 1 1 0;
  position: relative;
  background: lightblue;
}
.ratingDetailPanelInner {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 12px;
  mask-image: linear-gradient(black 80%, transparent 100%);
}
@media screen and (max-width: 900px) {
  .ratingDetailPanelInner {
    padding: 8px;
    line-height: 16px;
  }
}
.unratedPool {
  flex: 2 1 0;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}
.extra {
  flex: 0 1 100px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
