.accountSelection {
  margin: 30px 24px;
  display: flex;
  justify-content: center;
}

.accountListItem {
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
}
.accountListItem.account {
  cursor: pointer;
}

.newAccountButton {
  margin-top: 24px;
}
