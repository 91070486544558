.layout {
  --header-height: 52px;
  --height: 100%;
  height: var(--height);
  width: 100%;
  position: fixed;
  overflow-x: hidden;
  /*
  added !important to this to clumsily fix problem: when dynamic content is controlling the content
  height in antd rows, switching to content that fits on screen, then back to content that exceeds it,
  the scroll bar disappears (style is overridden with "initial" somewhere.
  */
  overflow-y: auto !important;
}

.main {
  position: relative;
  background-color: var(--app-content-background-color);
  min-height: calc(100% - var(--header-height));
  display: flex;
  flex-direction: column;
}

.header {
  height: var(--header-height);
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  background-color: var(--color-blue-dark);
}
.header > div {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}
.logo {
  display: inline-block;
  height: 28px;
}

@media screen and (max-width: 600px) {
  .layout {
    --header-height: 38px;
  }

  .header {
    padding: 8px 12px;
  }

  .accountName,
  .actionQueueSaveStatus {
    display: none;
  }

  .logo {
    height: 20px;
  }
}
