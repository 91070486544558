.node {
  line-height: 2;
  padding: 6px 12px;
  border-radius: 2px;
}
.node.hasClickHandler {
  cursor: pointer;
}
.node.readOnly {
  cursor: auto;
}
.node.disabled {
  cursor: auto;
  color: darkgray;
}
.node.selected {
  /* TODO: use color var (https://vms.atlassian.net/browse/VP3-2566) */
  background-color: rgba(186, 231, 255, 1);
}

/* When "skipRoot" property is set, the root children don't have a parent, and are therefore
   flush with the container. */
.treeNodeChildrenFlush {
  margin-left: 0;
}

.treeNodeChildren {
  margin-left: 20px;
}
