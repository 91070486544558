.ratingScale {
  --rating-scale-total-span: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  user-select: none;
}

.ratingScaleItem {
  --rating-scale-item-span: 0;
  height: calc(var(--rating-scale-item-span) / var(--rating-scale-total-span) * 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: lightgray;
}
.ratingScaleItem:not(:last-of-type) {
  border-bottom: 3px dotted lightgray;
}
