.ratingOverview {
  flex: 1;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 36px;
  text-align: center;
}

.backButton {
  margin-top: auto;
}

.completedList {
  list-style: none;
  padding: 0;
}

@media screen and (min-width: 600px) {
  .ratingOverview {
    font-size: 16px;
  }
  .ratingOverview h1 {
    font-size: 3em;
  }
  .ratingOverview h2 {
    font-size: 2em;
  }
  .ratingOverview h3 {
    font-size: 1.5em;
  }
}
