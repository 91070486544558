.ratingSubject {
  position: relative;
  cursor: grab;
  text-align: center;
  outline: none;
  touch-action: none;
  user-select: none;
  max-width: min-content;
  max-height: min-content;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0);
  z-index: calc(100 + var(--subject-z-index, 0));
}
.ratingSubject.hasRating {
  position: absolute;
  left: var(--position-left, 0);
  bottom: var(--position-bottom, 0);
}
.ratingSubject:active {
  --active-blue: rgb(81, 203, 238);
  box-shadow: 0 0 5px var(--active-blue), 0 0 0 1px var(--active-blue);
  cursor: grabbing;
}
.ratingSubject.disabled {
  opacity: 0.25;
  cursor: default;
}
.ratingSubject.disabled:active {
  cursor: default;
  box-shadow: none;
}

.subject {
  position: relative;
  height: 54px;
  width: 60px;
  padding: 2px;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: var(--subject-font-size, 16px);
  font-family: 'Pragati Narrow';
}
.subjectName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.subjectRating {
  visibility: visible;
}
.subjectRating.hidden {
  visibility: hidden;
}
.subjectRating.displayNone {
  display: none;
}
.subjectBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  z-index: -1;
}
.subjectBackgroundColor {
  background-color: var(--subject-color, #ffffff);
  height: 100%;
  width: 100%;
  opacity: 0.6;
}
