.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.controls > div {
  display: flex;
  align-items: center;
  gap: 24px;
}

.chartRow {
  padding-top: 20px;
  display: flex;
  gap: 48px;
}
.chartGroup {
  flex: 1 1 0;
  /* non-flex size values need to be set on the parent of ResponsiveContainer */
  min-width: 0;
  min-height: 0;
}
