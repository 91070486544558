.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 12px;
}

/*
  using the `.ant-drawer-body` selector on its own doesn't work with the vanilla
  class attribute because classnames declared in css modules end up being
  hashed. we need to use selector composition and apply a hashed classname to an
  ancestor element and use the attribute selector to select nested elements with
  the target class attribute value.
*/
.drawer [class='ant-drawer-body'] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}
